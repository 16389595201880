/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Nice's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@use "@recursyve/nice-ui-kit.v2" as nice;
@use "@recursyve/nice-data-filter-kit" as dataFilter;

@import "../app/themes/button.theme";
@import "../app/themes/form-control.theme";

@import "custom-classes";

@include nice.core();

/*
Firefox text alignment issue:

Firefox calculates text baselines differently than other browsers, so the text is sometimes too high in the text box.
It looks like the font has something to do with it, since removing the font fixes the problem.

https://stackoverflow.com/questions/26385334/css-displayflex-align-itemsbaseline-not-working-in-firefox
 */

body.light,
body .light {
    $light-theme: nice.nice-light-theme();
    @include dataFilter.nice-data-filter-kit-theme($light-theme);

    .nice-vertical-navigation-wrapper {
        @apply shadow-card #{!important};
    }

    .mat-card {
        @apply bg-white;
        box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.06), 0px 3px 4px 0px rgba(0, 0, 0, 0.042),
            0px 1px 8px 0px rgba(0, 0, 0, 0.036) !important;
    }

    .mat-card-transparent {
        &.mat-card {
            background: transparent !important;
            box-shadow: none !important;
        }
    }

    .cdk-overlay-dark-backdrop {
        background-color: rgba(216, 216, 216, 0.2);
    }

    .mat-dialog-container {
        color: rgba(0, 0, 0, 0.54);
        background-color: #fafafa;

        border-radius: 12px;
        box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.06);

        .mat-toolbar {
            color: rgba(0, 0, 0, 0.54);
            background-color: #fafafa;

            .mat-toolbar-row {
                padding: 0 32px;

                @include nice.media-breakpoint("lt-md") {
                    padding: 0;
                }
            }

            .mat-icon {
                font-size: 30px;
                line-height: 30px;
                width: 30px;
                height: 30px;
            }
        }

        .dialog-title {
            font-size: 30px;
        }
    }

    .ngx-mat-timepicker {
        mat-form-field {
            padding: 0 !important;

            .mat-form-field-wrapper {
                padding-bottom: unset !important;

                .mat-form-field-underline {
                    position: unset !important;
                }
            }
        }

        input {
            font-size: unset !important;
            width: unset !important;
            padding: 0 !important;
            border: none !important;
            height: unset !important;
        }
    }

    .mat-menu-panel {
        @apply max-w-96;
        border-radius: 12px;
        box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12) !important;
        min-height: unset !important;

        .mat-menu-content {
            padding: 0 !important;
        }

        .mat-menu-item {
            @apply text-on-primary-100/70;
        }
    }

    .mat-tab-group {
        .mat-tab-label-active {
            opacity: 1 !important;
        }

        .mat-tab-label:not(mat-tab-label-active) {
            color: rgb(125, 129, 140);
            font-weight: bold;
        }

        &.mat-accent .mat-tab-label-active,
        .mat-tab-label:focus:not(.mat-tab-disabled) {
            @apply text-accent;
        }
    }

    .mat-tab-header {
        border-bottom: none !important;
    }

    .mat-tab-list {
        .mat-tab-label {
            height: 36px;
            padding: 0;
            margin-right: 48px;
            justify-content: flex-start;

            .mat-tab-label-content {
                font-size: 16px;
            }

            @include nice.media-breakpoint("lt-md") {
                margin-right: 24px;
            }
        }

        .mat-ink-bar {
            width: 45px !important;
        }
    }

    mat-list.acl-custom-list {
        background-color: white;
        border-radius: 12px;
        box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.06);

        &.mat-list-base {
            padding: 0 !important;
        }

        mat-list-item {
            height: auto !important;
            padding-top: 12px !important;
            padding-bottom: 12px !important;

            &:not(:last-child) {
                border-bottom: solid 2px rgba(92, 92, 92, 0.1);
            }
        }
    }

    .toggle-buttons,
    .toggle-button-group {
        @apply w-28 h-14 overflow-hidden #{!important};
    }

    .toggle-button {
        @apply w-14 h-14 min-w-0 #{!important};
    }

    .toggle-button-group {
        .toggle-button:first-child {
            @apply rounded-none rounded-l-xl #{!important};
        }

        .toggle-button:last-child {
            @apply rounded-none rounded-r-xl #{!important};
        }
    }

    .toggle-button:not(.selected) {
        @apply bg-accent/50 #{!important};
    }

    .selected {
        @apply bg-accent #{!important};
    }

    .toggle-button-group {
        @apply bg-none #{!important};
    }

    .toggle-button-inner {
        i {
            @apply text-white #{!important};
        }
    }

    .mat-form-field-autofill-control {
        @apply font-normal text-base leading-5;
    }

    ngx-mat-timepicker {
        mat-form-field {
            @apply min-w-24 max-w-24 #{!important};

            .mat-form-field-flex {
                @apply bg-transparent #{!important};

                input {
                    @apply text-lg #{!important};
                }
            }
        }
    }
}

.nice-alert-modal {
    max-width: 500px !important;

    mat-dialog-actions {
        padding-bottom: 32px !important;

        .mat-raised-button {
            box-shadow: none !important;
        }
    }
}

div.mat-form-field-subscript-wrapper {
    @apply min-h-4 pl-4 #{!important};
}

.icon-size-38 {
    width: 9.5rem /* 152px */ !important;
    height: 9.5rem /* 152px */ !important;
    min-width: 9.5rem /* 152px */ !important;
    min-height: 9.5rem /* 152px */ !important;
    font-size: 9.5rem /* 152px */ !important;
    line-height: 9.5rem /* 152px */ !important;
}
.icon-size-38 svg {
    width: 9.5rem /* 152px */ !important;
    height: 9.5rem /* 152px */ !important;
}

mat-form-field.mat-form-field.mat-form-field-invalid {
    .mat-form-field-prefix {
        @apply text-warn;
    }
}

mat-form-field.mat-form-field-appearance-outline {
    @apply pb-0 rounded-xl #{!important};

    .mat-form-field-flex {
        @apply h-12 mb-0 border-0 rounded-xl shadow-none #{!important};

        .mat-form-field-prefix,
        .mat-form-field-suffix {
            @apply -translate-y-0.5;
        }

        .mat-form-field-suffix {
            @apply pr-3;
        }

        .mat-form-field-infix {
            @apply h-12 py-0;

            mat-select {
                @apply h-12 border-t-0 pt-0;

                .mat-select-trigger {
                    @apply pb-0 h-full;
                }

                .mat-select-value {
                    @apply pt-0 border-t-0;

                    .mat-select-value-text {
                        @apply mt-0;
                    }

                    .mat-select-placeholder {
                        @apply mt-0;
                    }
                }

                .mat-select-arrow,
                .mat-select-arrow-wrapper {
                    @apply h-4 w-4 m-0 #{!important};
                }

                .mat-select-arrow::before {
                    @apply text-primary h-4 w-4;
                }
            }

            input {
                @apply flex h-11;
                width: -webkit-fill-available;
            }

            .mat-form-field-label-wrapper {
                @apply translate-y-1.5;
            }

            nice-async-typeahead {
                @apply h-12 #{!important};

                input {
                    @apply h-11;
                }
            }
        }

        .mat-form-field-outline {
            @apply h-full rounded-xl -translate-y-1 #{!important};

            .mat-form-field-outline-start {
                @apply rounded-l-xl min-w-7 #{!important};
            }

            .mat-form-field-outline-end {
                @apply rounded-r-xl min-w-7 #{!important};
            }
        }
    }
}
