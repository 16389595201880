.equipment-dropdown {
    .mat-form-field-wrapper {
        @apply m-0 p-0 #{!important};
    }

    .mat-form-field-flex {
        @apply rounded-xl bg-white #{!important};
    }
}

.reserved-dropdown {
    span {
        @apply text-reserved-500;
    }

    .mat-form-field-flex {
        @apply rounded-xl border-reserved-500 bg-reserved-500 #{!important};
        .mat-select-min-line,
        .mat-select-arrow {
            @apply text-white #{!important};
        }
    }
}

.with-client-dropdown {
    span {
        @apply text-accent;
    }

    .mat-form-field-flex {
        @apply rounded-xl border-accent bg-accent #{!important};
        .mat-select-min-line,
        .mat-select-arrow {
            @apply text-white #{!important};
        }
    }
}

.maintenance-dropdown {
    span {
        @apply text-dark-red-500;
    }

    .mat-form-field-flex {
        @apply rounded-xl border-dark-red-500 bg-dark-red-500 #{!important};
        .mat-select-min-line,
        .mat-select-arrow {
            @apply text-white #{!important};
        }
    }
}

.to-collect-dropdown {
    span {
        @apply text-toPickup-500;
    }

    .mat-form-field-flex {
        @apply rounded-xl border-toPickup-500 bg-toPickup-500 #{!important};
        .mat-select-min-line,
        .mat-select-arrow {
            @apply text-white #{!important};
        }
    }
}

.usage-ended-dropdown {
    span {
        @apply text-endOfUse-500;
    }

    .mat-form-field-flex {
        @apply rounded-xl border-endOfUse-500 bg-endOfUse-500 #{!important};
        .mat-select-min-line,
        .mat-select-arrow {
            @apply text-white #{!important};
        }
    }
}

.available-dropdown {
    .mat-form-field-wrapper {
        @apply m-0 p-0 #{!important};
    }

    .mat-form-field-flex {
        @apply rounded-xl border-primary/20 bg-white #{!important};
    }
}

.reserved-chip {
    @apply bg-reserved-500 #{!important};

    span {
        @apply text-white;
    }
}

.with-client-chip {
    @apply bg-accent #{!important};

    span {
        @apply text-white;
    }
}

.maintenance-chip {
    @apply bg-dark-red-500 #{!important};

    span {
        @apply text-white;
    }
}

.to-collect-chip {
    @apply bg-toPickup-500 #{!important};

    span {
        @apply text-white;
    }
}

.usage-ended-chip {
    @apply bg-endOfUse-500 #{!important};

    span {
        @apply text-white;
    }
}

.available-chip {
    @apply bg-white rounded-xl border border-primary/20 #{!important};

    span {
        @apply text-primary;
    }
}

.with-client-text {
    @apply text-accent font-bold;
}

.reserved-text {
    @apply text-reserved-500 font-bold;
}

.maintenance-text {
    @apply text-dark-red-500 font-bold;
}

.to-collect-text {
    @apply text-toPickup-500 font-bold;
}

.usage-ended-text {
    @apply text-endOfUse-500 font-bold;
}

.available-text {
    @apply font-bold;
}

@media print {
    body * {
        @apply invisible;
    }

    .printable,
    .printable * {
        @apply visible;
    }

    .printable {
        @apply absolute left-0 top-0;
    }
}

.disabled {
    @apply opacity-50 select-none pointer-events-none;
}
