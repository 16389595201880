mat-form-field {
    width: 100%;
    padding-bottom: 24px;

    &.mat-form-field-invalid {
        .mat-form-field-flex {
            @apply border-2 border-warn;
        }
    }

    &.mat-form-field:not(.mat-form-field-invalid) .mat-form-field-label {
        @apply text-primary/80;
    }

    &.mat-form-field .mat-form-field-label {
        font-weight: 600;
        height: 100%;
    }

    &.mat-form-field:not(.mat-form-field-should-float) .mat-form-field-label {
        margin-top: 14px;
        margin-left: 16px;
    }

    .mat-form-field-prefix + .mat-form-field-infix .mat-form-field-label-wrapper {
        margin-top: 0;
        margin-left: -46px;
    }

    .mat-form-field-flex {
        align-items: center !important;
        background-color: white;
        box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.06);
        border: solid 1.5px transparent;
        border-radius: 12px;
    }

    .mat-select-trigger {
        height: 100%;
        padding-bottom: 8px;
    }

    .mat-select-arrow-wrapper {
        font-size: 26px;
        margin-right: 30px;
        margin-bottom: 15px;

        .mat-select-arrow {
            position: relative;
            font-family: 'Font Awesome 5 Pro';
            font-weight: 300;

            border: none;
            margin: 0;

            &:before {
                position: absolute;
                transform: translateY(-15%);
                content: "\f107";
            }
        }
    }

    textarea {
        margin: 4px !important;
    }

    .mat-input-element, .mat-select, .stripe-element {
        &:not(textarea) {
            height: 56px;
        }

        font-size: 20px;
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 4px;
        font-weight: 300;

        border: 1.5px solid transparent;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;

        @apply text-primary;

        &::placeholder {
            color: #b3b3b3 !important;
        }

        .mat-select-placeholder,
        .mat-select-value-text {
            margin-top: 8px;
            padding-top: 8px;
            padding-bottom: 8px;

            @apply text-primary;
            opacity: 1;
        }
    }

    .mat-form-field-prefix {
        padding-left: 16px;
        padding-right: 4px;
    }

    .mat-form-field-infix {
        padding-bottom: 4px;
        border: none;
        padding-top: 0;
    }

    &.mat-focused {
        .mat-form-field-required-marker {
            @apply text-primary;
        }
    }

    &.mat-form-field-should-float {
        .mat-form-field-label-wrapper {
            top: -22px;
        }
    }

    .mat-form-field-label-wrapper {
        top: -15px;

        label {
            @apply text-primary;
        }

        .mat-form-field-label {
            height: 20px;
        }
    }

    /* Change autocomplete styles in WebKit */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 1px solid transparent;
        -webkit-text-fill-color: #000;
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}

.ngx-mat-timepicker {
    mat-form-field {
        padding: 0 !important;

        .mat-form-field-wrapper {
            padding-bottom: unset !important;

            .mat-form-field-underline {
                position: unset !important;
            }
        }
    }

    input {
        font-size: unset !important;
        width: unset !important;
        padding: 0 !important;
        border: none !important;
        height: unset !important;
    }
}

.mat-select-panel-wrap {
    .mat-select-panel {
        border-radius: 12px;
    }

    mat-option:not(:last-child) {
        @apply border-b-2 border-primary/10;
    }
}
