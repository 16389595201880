/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind base;
@tailwind utilities;
@tailwind components;

@layer base {
    span,
    a {
        @apply font-normal text-base leading-5;

        // Très grand titre
        &.h1 {
            @apply text-2xl font-bold leading-9 #{!important};
        }

        // Grand titre
        &.h2 {
            @apply text-xl font-bold leading-normal #{!important};
        }

        // Titre
        &.h3 {
            @apply text-lg font-bold leading-normal #{!important};
        }

        // Intertitre
        &.h4 {
            @apply text-md font-medium leading-6 #{!important};
        }

        // Intertitre bold
        &.h5 {
            @apply text-md font-bold leading-normal #{!important};
        }

        &.link {
            @apply text-sm font-medium leading-4 #{!important};
        }
    }

    label {
        @apply text-xs font-normal leading-normal #{!important};
    }

    body {
        @apply text-base font-normal leading-normal text-primary;
    }
}

@layer components {
    .mat-button,
    .mat-raised-button,
    .mat-icon-button,
    .mat-stroked-button,
    .mat-flat-button,
    .mat-button-base,
    .mat-flat-button {
        @apply rounded-xl text-base font-bold leading-normal #{!important};
    }

    .mat-flat-button {
        @apply bg-accent text-white #{!important};
    }

    .mat-stroked-button {
        @apply text-accent border-orange-500 border #{!important};
    }

    mat-card {
        @apply shadow rounded-xl #{!important};
    }
}
