.mat-button-base:not(.mat-icon-button, .mat-fab, .mat-mini-fab) {
    border-radius: 12px;
    border: solid 1px transparent !important;

    text-transform: uppercase;

    padding-left: 32px;
    padding-right: 32px;
}

.mat-flat-button {
    font-size: 18px;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
}

.mat-flat-button.mat-button-disabled {
    &.mat-accent {
        @apply bg-accent/50 #{!important};
    }
}

.mat-stroked-button {
    &:not(.mat-button-disabled) {
        &.white {
            @apply border-white #{!important};
        }

        &.mat-accent {
            @apply border-accent #{!important};
        }

        &.mat-primary {
            @apply border-primary #{!important};
        }

        &.mat-warn {
            @apply border-warn #{!important};
        }
    }

    &.mat-button-disabled {
        border-color: inherit;
    }
}
